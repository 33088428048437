import React from "react";

const Polly = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.81738"
        y="3.81836"
        width="6.36364"
        height="8.59091"
        fill="#FFECC3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.91667 4.08333H4.08333V12.25H9.91667V4.08333ZM3.5 3.5V12.8333H10.5V3.5H3.5Z"
        fill="#333333"
      />
      <path d="M3.5 1.75L10.5 2.33333V4.08333H3.5V1.75Z" fill="#333333" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6663 2.91737L2.33292 1.7507L2.40527 1.17188L11.7386 2.33854L11.6663 2.91737Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66634 5.13398C4.92407 5.13398 5.13301 4.92505 5.13301 4.66732C5.13301 4.40958 4.92407 4.20065 4.66634 4.20065C4.40861 4.20065 4.19967 4.40958 4.19967 4.66732C4.19967 4.92505 4.40861 5.13398 4.66634 5.13398ZM4.66634 5.25065C4.98851 5.25065 5.24967 4.98948 5.24967 4.66732C5.24967 4.34515 4.98851 4.08398 4.66634 4.08398C4.34418 4.08398 4.08301 4.34515 4.08301 4.66732C4.08301 4.98948 4.34418 5.25065 4.66634 5.25065Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 4.55065C5.63832 4.55065 5.71667 4.4723 5.71667 4.37565C5.71667 4.279 5.63832 4.20065 5.54167 4.20065C5.44502 4.20065 5.36667 4.279 5.36667 4.37565C5.36667 4.4723 5.44502 4.55065 5.54167 4.55065ZM5.54167 4.66732C5.70275 4.66732 5.83333 4.53673 5.83333 4.37565C5.83333 4.21457 5.70275 4.08398 5.54167 4.08398C5.38058 4.08398 5.25 4.21457 5.25 4.37565C5.25 4.53673 5.38058 4.66732 5.54167 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 5.13464C5.63832 5.13464 5.71667 5.05629 5.71667 4.95964C5.71667 4.86299 5.63832 4.78464 5.54167 4.78464C5.44502 4.78464 5.36667 4.86299 5.36667 4.95964C5.36667 5.05629 5.44502 5.13464 5.54167 5.13464ZM5.54167 5.2513C5.70275 5.2513 5.83333 5.12072 5.83333 4.95964C5.83333 4.79855 5.70275 4.66797 5.54167 4.66797C5.38058 4.66797 5.25 4.79855 5.25 4.95964C5.25 5.12072 5.38058 5.2513 5.54167 5.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 5.71667C4.47132 5.71667 4.54967 5.63832 4.54967 5.54167C4.54967 5.44502 4.47132 5.36667 4.37467 5.36667C4.27802 5.36667 4.19967 5.44502 4.19967 5.54167C4.19967 5.63832 4.27802 5.71667 4.37467 5.71667ZM4.37467 5.83333C4.53576 5.83333 4.66634 5.70275 4.66634 5.54167C4.66634 5.38058 4.53576 5.25 4.37467 5.25C4.21359 5.25 4.08301 5.38058 4.08301 5.54167C4.08301 5.70275 4.21359 5.83333 4.37467 5.83333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 6.30065C4.47132 6.30065 4.54967 6.2223 4.54967 6.12565C4.54967 6.029 4.47132 5.95065 4.37467 5.95065C4.27802 5.95065 4.19967 6.029 4.19967 6.12565C4.19967 6.2223 4.27802 6.30065 4.37467 6.30065ZM4.37467 6.41732C4.53576 6.41732 4.66634 6.28673 4.66634 6.12565C4.66634 5.96457 4.53576 5.83398 4.37467 5.83398C4.21359 5.83398 4.08301 5.96457 4.08301 6.12565C4.08301 6.28673 4.21359 6.41732 4.37467 6.41732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 6.88464C5.05433 6.88464 5.13268 6.80629 5.13268 6.70964C5.13268 6.61299 5.05433 6.53464 4.95768 6.53464C4.86103 6.53464 4.78268 6.61299 4.78268 6.70964C4.78268 6.80629 4.86103 6.88464 4.95768 6.88464ZM4.95768 7.0013C5.11877 7.0013 5.24935 6.87072 5.24935 6.70964C5.24935 6.54855 5.11877 6.41797 4.95768 6.41797C4.7966 6.41797 4.66602 6.54855 4.66602 6.70964C4.66602 6.87072 4.7966 7.0013 4.95768 7.0013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 6.88464C4.47132 6.88464 4.54967 6.80629 4.54967 6.70964C4.54967 6.61299 4.47132 6.53464 4.37467 6.53464C4.27802 6.53464 4.19967 6.61299 4.19967 6.70964C4.19967 6.80629 4.27802 6.88464 4.37467 6.88464ZM4.37467 7.0013C4.53576 7.0013 4.66634 6.87072 4.66634 6.70964C4.66634 6.54855 4.53576 6.41797 4.37467 6.41797C4.21359 6.41797 4.08301 6.54855 4.08301 6.70964C4.08301 6.87072 4.21359 7.0013 4.37467 7.0013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 6.88464C5.63832 6.88464 5.71667 6.80629 5.71667 6.70964C5.71667 6.61299 5.63832 6.53464 5.54167 6.53464C5.44502 6.53464 5.36667 6.61299 5.36667 6.70964C5.36667 6.80629 5.44502 6.88464 5.54167 6.88464ZM5.54167 7.0013C5.70275 7.0013 5.83333 6.87072 5.83333 6.70964C5.83333 6.54855 5.70275 6.41797 5.54167 6.41797C5.38058 6.41797 5.25 6.54855 5.25 6.70964C5.25 6.87072 5.38058 7.0013 5.54167 7.0013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 7.46667C5.63832 7.46667 5.71667 7.38832 5.71667 7.29167C5.71667 7.19502 5.63832 7.11667 5.54167 7.11667C5.44502 7.11667 5.36667 7.19502 5.36667 7.29167C5.36667 7.38832 5.44502 7.46667 5.54167 7.46667ZM5.54167 7.58333C5.70275 7.58333 5.83333 7.45275 5.83333 7.29167C5.83333 7.13058 5.70275 7 5.54167 7C5.38058 7 5.25 7.13058 5.25 7.29167C5.25 7.45275 5.38058 7.58333 5.54167 7.58333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 8.05065C5.63832 8.05065 5.71667 7.9723 5.71667 7.87565C5.71667 7.779 5.63832 7.70065 5.54167 7.70065C5.44502 7.70065 5.36667 7.779 5.36667 7.87565C5.36667 7.9723 5.44502 8.05065 5.54167 8.05065ZM5.54167 8.16732C5.70275 8.16732 5.83333 8.03673 5.83333 7.87565C5.83333 7.71457 5.70275 7.58398 5.54167 7.58398C5.38058 7.58398 5.25 7.71457 5.25 7.87565C5.25 8.03673 5.38058 8.16732 5.54167 8.16732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 8.63464C5.05433 8.63464 5.13268 8.55629 5.13268 8.45964C5.13268 8.36299 5.05433 8.28464 4.95768 8.28464C4.86103 8.28464 4.78268 8.36299 4.78268 8.45964C4.78268 8.55629 4.86103 8.63464 4.95768 8.63464ZM4.95768 8.7513C5.11877 8.7513 5.24935 8.62072 5.24935 8.45964C5.24935 8.29855 5.11877 8.16797 4.95768 8.16797C4.7966 8.16797 4.66602 8.29855 4.66602 8.45964C4.66602 8.62072 4.7966 8.7513 4.95768 8.7513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 8.63464C4.47132 8.63464 4.54967 8.55629 4.54967 8.45964C4.54967 8.36299 4.47132 8.28464 4.37467 8.28464C4.27802 8.28464 4.19967 8.36299 4.19967 8.45964C4.19967 8.55629 4.27802 8.63464 4.37467 8.63464ZM4.37467 8.7513C4.53576 8.7513 4.66634 8.62072 4.66634 8.45964C4.66634 8.29855 4.53576 8.16797 4.37467 8.16797C4.21359 8.16797 4.08301 8.29855 4.08301 8.45964C4.08301 8.62072 4.21359 8.7513 4.37467 8.7513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 9.21667C4.47132 9.21667 4.54967 9.13832 4.54967 9.04167C4.54967 8.94502 4.47132 8.86667 4.37467 8.86667C4.27802 8.86667 4.19967 8.94502 4.19967 9.04167C4.19967 9.13832 4.27802 9.21667 4.37467 9.21667ZM4.37467 9.33333C4.53576 9.33333 4.66634 9.20275 4.66634 9.04167C4.66634 8.88058 4.53576 8.75 4.37467 8.75C4.21359 8.75 4.08301 8.88058 4.08301 9.04167C4.08301 9.20275 4.21359 9.33333 4.37467 9.33333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 9.21667C5.05433 9.21667 5.13268 9.13832 5.13268 9.04167C5.13268 8.94502 5.05433 8.86667 4.95768 8.86667C4.86103 8.86667 4.78268 8.94502 4.78268 9.04167C4.78268 9.13832 4.86103 9.21667 4.95768 9.21667ZM4.95768 9.33333C5.11877 9.33333 5.24935 9.20275 5.24935 9.04167C5.24935 8.88058 5.11877 8.75 4.95768 8.75C4.7966 8.75 4.66602 8.88058 4.66602 9.04167C4.66602 9.20275 4.7966 9.33333 4.95768 9.33333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 9.80065C5.05433 9.80065 5.13268 9.7223 5.13268 9.62565C5.13268 9.529 5.05433 9.45065 4.95768 9.45065C4.86103 9.45065 4.78268 9.529 4.78268 9.62565C4.78268 9.7223 4.86103 9.80065 4.95768 9.80065ZM4.95768 9.91732C5.11877 9.91732 5.24935 9.78673 5.24935 9.62565C5.24935 9.46457 5.11877 9.33398 4.95768 9.33398C4.7966 9.33398 4.66602 9.46457 4.66602 9.62565C4.66602 9.78673 4.7966 9.91732 4.95768 9.91732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 9.80065C4.47132 9.80065 4.54967 9.7223 4.54967 9.62565C4.54967 9.529 4.47132 9.45065 4.37467 9.45065C4.27802 9.45065 4.19967 9.529 4.19967 9.62565C4.19967 9.7223 4.27802 9.80065 4.37467 9.80065ZM4.37467 9.91732C4.53576 9.91732 4.66634 9.78673 4.66634 9.62565C4.66634 9.46457 4.53576 9.33398 4.37467 9.33398C4.21359 9.33398 4.08301 9.46457 4.08301 9.62565C4.08301 9.78673 4.21359 9.91732 4.37467 9.91732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 11.5507C4.47132 11.5507 4.54967 11.4723 4.54967 11.3757C4.54967 11.279 4.47132 11.2007 4.37467 11.2007C4.27802 11.2007 4.19967 11.279 4.19967 11.3757C4.19967 11.4723 4.27802 11.5507 4.37467 11.5507ZM4.37467 11.6673C4.53576 11.6673 4.66634 11.5367 4.66634 11.3757C4.66634 11.2146 4.53576 11.084 4.37467 11.084C4.21359 11.084 4.08301 11.2146 4.08301 11.3757C4.08301 11.5367 4.21359 11.6673 4.37467 11.6673Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 10.3846C5.63832 10.3846 5.71667 10.3063 5.71667 10.2096C5.71667 10.113 5.63832 10.0346 5.54167 10.0346C5.44502 10.0346 5.36667 10.113 5.36667 10.2096C5.36667 10.3063 5.44502 10.3846 5.54167 10.3846ZM5.54167 10.5013C5.70275 10.5013 5.83333 10.3707 5.83333 10.2096C5.83333 10.0486 5.70275 9.91797 5.54167 9.91797C5.38058 9.91797 5.25 10.0486 5.25 10.2096C5.25 10.3707 5.38058 10.5013 5.54167 10.5013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 9.80065C5.63832 9.80065 5.71667 9.7223 5.71667 9.62565C5.71667 9.529 5.63832 9.45065 5.54167 9.45065C5.44502 9.45065 5.36667 9.529 5.36667 9.62565C5.36667 9.7223 5.44502 9.80065 5.54167 9.80065ZM5.54167 9.91732C5.70275 9.91732 5.83333 9.78673 5.83333 9.62565C5.83333 9.46457 5.70275 9.33398 5.54167 9.33398C5.38058 9.33398 5.25 9.46457 5.25 9.62565C5.25 9.78673 5.38058 9.91732 5.54167 9.91732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 10.9667C5.63832 10.9667 5.71667 10.8883 5.71667 10.7917C5.71667 10.695 5.63832 10.6167 5.54167 10.6167C5.44502 10.6167 5.36667 10.695 5.36667 10.7917C5.36667 10.8883 5.44502 10.9667 5.54167 10.9667ZM5.54167 11.0833C5.70275 11.0833 5.83333 10.9527 5.83333 10.7917C5.83333 10.6306 5.70275 10.5 5.54167 10.5C5.38058 10.5 5.25 10.6306 5.25 10.7917C5.25 10.9527 5.38058 11.0833 5.54167 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 11.5507C5.05433 11.5507 5.13268 11.4723 5.13268 11.3757C5.13268 11.279 5.05433 11.2007 4.95768 11.2007C4.86103 11.2007 4.78268 11.279 4.78268 11.3757C4.78268 11.4723 4.86103 11.5507 4.95768 11.5507ZM4.95768 11.6673C5.11877 11.6673 5.24935 11.5367 5.24935 11.3757C5.24935 11.2146 5.11877 11.084 4.95768 11.084C4.7966 11.084 4.66602 11.2146 4.66602 11.3757C4.66602 11.5367 4.7966 11.6673 4.95768 11.6673Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 11.5507C5.63832 11.5507 5.71667 11.4723 5.71667 11.3757C5.71667 11.279 5.63832 11.2007 5.54167 11.2007C5.44502 11.2007 5.36667 11.279 5.36667 11.3757C5.36667 11.4723 5.44502 11.5507 5.54167 11.5507ZM5.54167 11.6673C5.70275 11.6673 5.83333 11.5367 5.83333 11.3757C5.83333 11.2146 5.70275 11.084 5.54167 11.084C5.38058 11.084 5.25 11.2146 5.25 11.3757C5.25 11.5367 5.38058 11.6673 5.54167 11.6673Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.54167 12.1346C5.63832 12.1346 5.71667 12.0563 5.71667 11.9596C5.71667 11.863 5.63832 11.7846 5.54167 11.7846C5.44502 11.7846 5.36667 11.863 5.36667 11.9596C5.36667 12.0563 5.44502 12.1346 5.54167 12.1346ZM5.54167 12.2513C5.70275 12.2513 5.83333 12.1207 5.83333 11.9596C5.83333 11.7986 5.70275 11.668 5.54167 11.668C5.38058 11.668 5.25 11.7986 5.25 11.9596C5.25 12.1207 5.38058 12.2513 5.54167 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12467 12.1346C6.22132 12.1346 6.29967 12.0563 6.29967 11.9596C6.29967 11.863 6.22132 11.7846 6.12467 11.7846C6.02802 11.7846 5.94967 11.863 5.94967 11.9596C5.94967 12.0563 6.02802 12.1346 6.12467 12.1346ZM6.12467 12.2513C6.28576 12.2513 6.41634 12.1207 6.41634 11.9596C6.41634 11.7986 6.28576 11.668 6.12467 11.668C5.96359 11.668 5.83301 11.7986 5.83301 11.9596C5.83301 12.1207 5.96359 12.2513 6.12467 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70768 12.1346C6.80433 12.1346 6.88268 12.0563 6.88268 11.9596C6.88268 11.863 6.80433 11.7846 6.70768 11.7846C6.61103 11.7846 6.53268 11.863 6.53268 11.9596C6.53268 12.0563 6.61103 12.1346 6.70768 12.1346ZM6.70768 12.2513C6.86877 12.2513 6.99935 12.1207 6.99935 11.9596C6.99935 11.7986 6.86877 11.668 6.70768 11.668C6.5466 11.668 6.41602 11.7986 6.41602 11.9596C6.41602 12.1207 6.5466 12.2513 6.70768 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 12.1346C8.55433 12.1346 8.63268 12.0563 8.63268 11.9596C8.63268 11.863 8.55433 11.7846 8.45768 11.7846C8.36103 11.7846 8.28268 11.863 8.28268 11.9596C8.28268 12.0563 8.36103 12.1346 8.45768 12.1346ZM8.45768 12.2513C8.61877 12.2513 8.74935 12.1207 8.74935 11.9596C8.74935 11.7986 8.61877 11.668 8.45768 11.668C8.2966 11.668 8.16602 11.7986 8.16602 11.9596C8.16602 12.1207 8.2966 12.2513 8.45768 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 11.5507C8.55433 11.5507 8.63268 11.4723 8.63268 11.3757C8.63268 11.279 8.55433 11.2007 8.45768 11.2007C8.36103 11.2007 8.28268 11.279 8.28268 11.3757C8.28268 11.4723 8.36103 11.5507 8.45768 11.5507ZM8.45768 11.6673C8.61877 11.6673 8.74935 11.5367 8.74935 11.3757C8.74935 11.2146 8.61877 11.084 8.45768 11.084C8.2966 11.084 8.16602 11.2146 8.16602 11.3757C8.16602 11.5367 8.2966 11.6673 8.45768 11.6673Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 10.9667C8.55433 10.9667 8.63268 10.8883 8.63268 10.7917C8.63268 10.695 8.55433 10.6167 8.45768 10.6167C8.36103 10.6167 8.28268 10.695 8.28268 10.7917C8.28268 10.8883 8.36103 10.9667 8.45768 10.9667ZM8.45768 11.0833C8.61877 11.0833 8.74935 10.9527 8.74935 10.7917C8.74935 10.6306 8.61877 10.5 8.45768 10.5C8.2966 10.5 8.16602 10.6306 8.16602 10.7917C8.16602 10.9527 8.2966 11.0833 8.45768 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87467 10.9667C7.97132 10.9667 8.04967 10.8883 8.04967 10.7917C8.04967 10.695 7.97132 10.6167 7.87467 10.6167C7.77802 10.6167 7.69967 10.695 7.69967 10.7917C7.69967 10.8883 7.77802 10.9667 7.87467 10.9667ZM7.87467 11.0833C8.03576 11.0833 8.16634 10.9527 8.16634 10.7917C8.16634 10.6306 8.03576 10.5 7.87467 10.5C7.71359 10.5 7.58301 10.6306 7.58301 10.7917C7.58301 10.9527 7.71359 11.0833 7.87467 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 10.9667C7.38832 10.9667 7.46667 10.8883 7.46667 10.7917C7.46667 10.695 7.38832 10.6167 7.29167 10.6167C7.19502 10.6167 7.11667 10.695 7.11667 10.7917C7.11667 10.8883 7.19502 10.9667 7.29167 10.9667ZM7.29167 11.0833C7.45275 11.0833 7.58333 10.9527 7.58333 10.7917C7.58333 10.6306 7.45275 10.5 7.29167 10.5C7.13058 10.5 7 10.6306 7 10.7917C7 10.9527 7.13058 11.0833 7.29167 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 10.3846C7.38832 10.3846 7.46667 10.3063 7.46667 10.2096C7.46667 10.113 7.38832 10.0346 7.29167 10.0346C7.19502 10.0346 7.11667 10.113 7.11667 10.2096C7.11667 10.3063 7.19502 10.3846 7.29167 10.3846ZM7.29167 10.5013C7.45275 10.5013 7.58333 10.3707 7.58333 10.2096C7.58333 10.0486 7.45275 9.91797 7.29167 9.91797C7.13058 9.91797 7 10.0486 7 10.2096C7 10.3707 7.13058 10.5013 7.29167 10.5013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 9.80065C7.38832 9.80065 7.46667 9.7223 7.46667 9.62565C7.46667 9.529 7.38832 9.45065 7.29167 9.45065C7.19502 9.45065 7.11667 9.529 7.11667 9.62565C7.11667 9.7223 7.19502 9.80065 7.29167 9.80065ZM7.29167 9.91732C7.45275 9.91732 7.58333 9.78673 7.58333 9.62565C7.58333 9.46457 7.45275 9.33398 7.29167 9.33398C7.13058 9.33398 7 9.46457 7 9.62565C7 9.78673 7.13058 9.91732 7.29167 9.91732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87467 9.21667C7.97132 9.21667 8.04967 9.13832 8.04967 9.04167C8.04967 8.94502 7.97132 8.86667 7.87467 8.86667C7.77802 8.86667 7.69967 8.94502 7.69967 9.04167C7.69967 9.13832 7.77802 9.21667 7.87467 9.21667ZM7.87467 9.33333C8.03576 9.33333 8.16634 9.20275 8.16634 9.04167C8.16634 8.88058 8.03576 8.75 7.87467 8.75C7.71359 8.75 7.58301 8.88058 7.58301 9.04167C7.58301 9.20275 7.71359 9.33333 7.87467 9.33333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 9.21667C8.55433 9.21667 8.63268 9.13832 8.63268 9.04167C8.63268 8.94502 8.55433 8.86667 8.45768 8.86667C8.36103 8.86667 8.28268 8.94502 8.28268 9.04167C8.28268 9.13832 8.36103 9.21667 8.45768 9.21667ZM8.45768 9.33333C8.61877 9.33333 8.74935 9.20275 8.74935 9.04167C8.74935 8.88058 8.61877 8.75 8.45768 8.75C8.2966 8.75 8.16602 8.88058 8.16602 9.04167C8.16602 9.20275 8.2966 9.33333 8.45768 9.33333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 8.63464C8.55433 8.63464 8.63268 8.55629 8.63268 8.45964C8.63268 8.36299 8.55433 8.28464 8.45768 8.28464C8.36103 8.28464 8.28268 8.36299 8.28268 8.45964C8.28268 8.55629 8.36103 8.63464 8.45768 8.63464ZM8.45768 8.7513C8.61877 8.7513 8.74935 8.62072 8.74935 8.45964C8.74935 8.29855 8.61877 8.16797 8.45768 8.16797C8.2966 8.16797 8.16602 8.29855 8.16602 8.45964C8.16602 8.62072 8.2966 8.7513 8.45768 8.7513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 8.05065C8.55433 8.05065 8.63268 7.9723 8.63268 7.87565C8.63268 7.779 8.55433 7.70065 8.45768 7.70065C8.36103 7.70065 8.28268 7.779 8.28268 7.87565C8.28268 7.9723 8.36103 8.05065 8.45768 8.05065ZM8.45768 8.16732C8.61877 8.16732 8.74935 8.03673 8.74935 7.87565C8.74935 7.71457 8.61877 7.58398 8.45768 7.58398C8.2966 7.58398 8.16602 7.71457 8.16602 7.87565C8.16602 8.03673 8.2966 8.16732 8.45768 8.16732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 7.46667C8.55433 7.46667 8.63268 7.38832 8.63268 7.29167C8.63268 7.19502 8.55433 7.11667 8.45768 7.11667C8.36103 7.11667 8.28268 7.19502 8.28268 7.29167C8.28268 7.38832 8.36103 7.46667 8.45768 7.46667ZM8.45768 7.58333C8.61877 7.58333 8.74935 7.45275 8.74935 7.29167C8.74935 7.13058 8.61877 7 8.45768 7C8.2966 7 8.16602 7.13058 8.16602 7.29167C8.16602 7.45275 8.2966 7.58333 8.45768 7.58333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04167 7.46667C9.13832 7.46667 9.21667 7.38832 9.21667 7.29167C9.21667 7.19502 9.13832 7.11667 9.04167 7.11667C8.94502 7.11667 8.86667 7.19502 8.86667 7.29167C8.86667 7.38832 8.94502 7.46667 9.04167 7.46667ZM9.04167 7.58333C9.20275 7.58333 9.33333 7.45275 9.33333 7.29167C9.33333 7.13058 9.20275 7 9.04167 7C8.88058 7 8.75 7.13058 8.75 7.29167C8.75 7.45275 8.88058 7.58333 9.04167 7.58333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 7.46667C9.72132 7.46667 9.79967 7.38832 9.79967 7.29167C9.79967 7.19502 9.72132 7.11667 9.62467 7.11667C9.52802 7.11667 9.44967 7.19502 9.44967 7.29167C9.44967 7.38832 9.52802 7.46667 9.62467 7.46667ZM9.62467 7.58333C9.78576 7.58333 9.91634 7.45275 9.91634 7.29167C9.91634 7.13058 9.78576 7 9.62467 7C9.46359 7 9.33301 7.13058 9.33301 7.29167C9.33301 7.45275 9.46359 7.58333 9.62467 7.58333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 6.88464C9.72132 6.88464 9.79967 6.80629 9.79967 6.70964C9.79967 6.61299 9.72132 6.53464 9.62467 6.53464C9.52802 6.53464 9.44967 6.61299 9.44967 6.70964C9.44967 6.80629 9.52802 6.88464 9.62467 6.88464ZM9.62467 7.0013C9.78576 7.0013 9.91634 6.87072 9.91634 6.70964C9.91634 6.54855 9.78576 6.41797 9.62467 6.41797C9.46359 6.41797 9.33301 6.54855 9.33301 6.70964C9.33301 6.87072 9.46359 7.0013 9.62467 7.0013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 6.30065C9.72132 6.30065 9.79967 6.2223 9.79967 6.12565C9.79967 6.029 9.72132 5.95065 9.62467 5.95065C9.52802 5.95065 9.44967 6.029 9.44967 6.12565C9.44967 6.2223 9.52802 6.30065 9.62467 6.30065ZM9.62467 6.41732C9.78576 6.41732 9.91634 6.28673 9.91634 6.12565C9.91634 5.96457 9.78576 5.83398 9.62467 5.83398C9.46359 5.83398 9.33301 5.96457 9.33301 6.12565C9.33301 6.28673 9.46359 6.41732 9.62467 6.41732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 4.55065C9.72132 4.55065 9.79967 4.4723 9.79967 4.37565C9.79967 4.279 9.72132 4.20065 9.62467 4.20065C9.52802 4.20065 9.44967 4.279 9.44967 4.37565C9.44967 4.4723 9.52802 4.55065 9.62467 4.55065ZM9.62467 4.66732C9.78576 4.66732 9.91634 4.53673 9.91634 4.37565C9.91634 4.21457 9.78576 4.08398 9.62467 4.08398C9.46359 4.08398 9.33301 4.21457 9.33301 4.37565C9.33301 4.53673 9.46359 4.66732 9.62467 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04167 4.55065C9.13832 4.55065 9.21667 4.4723 9.21667 4.37565C9.21667 4.279 9.13832 4.20065 9.04167 4.20065C8.94502 4.20065 8.86667 4.279 8.86667 4.37565C8.86667 4.4723 8.94502 4.55065 9.04167 4.55065ZM9.04167 4.66732C9.20275 4.66732 9.33333 4.53673 9.33333 4.37565C9.33333 4.21457 9.20275 4.08398 9.04167 4.08398C8.88058 4.08398 8.75 4.21457 8.75 4.37565C8.75 4.53673 8.88058 4.66732 9.04167 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 4.55065C7.38832 4.55065 7.46667 4.4723 7.46667 4.37565C7.46667 4.279 7.38832 4.20065 7.29167 4.20065C7.19502 4.20065 7.11667 4.279 7.11667 4.37565C7.11667 4.4723 7.19502 4.55065 7.29167 4.55065ZM7.29167 4.66732C7.45275 4.66732 7.58333 4.53673 7.58333 4.37565C7.58333 4.21457 7.45275 4.08398 7.29167 4.08398C7.13058 4.08398 7 4.21457 7 4.37565C7 4.53673 7.13058 4.66732 7.29167 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70768 4.55065C6.80433 4.55065 6.88268 4.4723 6.88268 4.37565C6.88268 4.279 6.80433 4.20065 6.70768 4.20065C6.61103 4.20065 6.53268 4.279 6.53268 4.37565C6.53268 4.4723 6.61103 4.55065 6.70768 4.55065ZM6.70768 4.66732C6.86877 4.66732 6.99935 4.53673 6.99935 4.37565C6.99935 4.21457 6.86877 4.08398 6.70768 4.08398C6.5466 4.08398 6.41602 4.21457 6.41602 4.37565C6.41602 4.53673 6.5466 4.66732 6.70768 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12467 4.55065C6.22132 4.55065 6.29967 4.4723 6.29967 4.37565C6.29967 4.279 6.22132 4.20065 6.12467 4.20065C6.02802 4.20065 5.94967 4.279 5.94967 4.37565C5.94967 4.4723 6.02802 4.55065 6.12467 4.55065ZM6.12467 4.66732C6.28576 4.66732 6.41634 4.53673 6.41634 4.37565C6.41634 4.21457 6.28576 4.08398 6.12467 4.08398C5.96359 4.08398 5.83301 4.21457 5.83301 4.37565C5.83301 4.53673 5.96359 4.66732 6.12467 4.66732Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 5.71667C7.38832 5.71667 7.46667 5.63832 7.46667 5.54167C7.46667 5.44502 7.38832 5.36667 7.29167 5.36667C7.19502 5.36667 7.11667 5.44502 7.11667 5.54167C7.11667 5.63832 7.19502 5.71667 7.29167 5.71667ZM7.29167 5.83333C7.45275 5.83333 7.58333 5.70275 7.58333 5.54167C7.58333 5.38058 7.45275 5.25 7.29167 5.25C7.13058 5.25 7 5.38058 7 5.54167C7 5.70275 7.13058 5.83333 7.29167 5.83333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.29167 5.13464C7.38832 5.13464 7.46667 5.05629 7.46667 4.95964C7.46667 4.86299 7.38832 4.78464 7.29167 4.78464C7.19502 4.78464 7.11667 4.86299 7.11667 4.95964C7.11667 5.05629 7.19502 5.13464 7.29167 5.13464ZM7.29167 5.2513C7.45275 5.2513 7.58333 5.12072 7.58333 4.95964C7.58333 4.79855 7.45275 4.66797 7.29167 4.66797C7.13058 4.66797 7 4.79855 7 4.95964C7 5.12072 7.13058 5.2513 7.29167 5.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87467 5.71667C7.97132 5.71667 8.04967 5.63832 8.04967 5.54167C8.04967 5.44502 7.97132 5.36667 7.87467 5.36667C7.77802 5.36667 7.69967 5.44502 7.69967 5.54167C7.69967 5.63832 7.77802 5.71667 7.87467 5.71667ZM7.87467 5.83333C8.03576 5.83333 8.16634 5.70275 8.16634 5.54167C8.16634 5.38058 8.03576 5.25 7.87467 5.25C7.71359 5.25 7.58301 5.38058 7.58301 5.54167C7.58301 5.70275 7.71359 5.83333 7.87467 5.83333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.45768 5.71667C8.55433 5.71667 8.63268 5.63832 8.63268 5.54167C8.63268 5.44502 8.55433 5.36667 8.45768 5.36667C8.36103 5.36667 8.28268 5.44502 8.28268 5.54167C8.28268 5.63832 8.36103 5.71667 8.45768 5.71667ZM8.45768 5.83333C8.61877 5.83333 8.74935 5.70275 8.74935 5.54167C8.74935 5.38058 8.61877 5.25 8.45768 5.25C8.2966 5.25 8.16602 5.38058 8.16602 5.54167C8.16602 5.70275 8.2966 5.83333 8.45768 5.83333Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87467 8.63464C7.97132 8.63464 8.04967 8.55629 8.04967 8.45964C8.04967 8.36299 7.97132 8.28464 7.87467 8.28464C7.77802 8.28464 7.69967 8.36299 7.69967 8.45964C7.69967 8.55629 7.77802 8.63464 7.87467 8.63464ZM7.87467 8.7513C8.03576 8.7513 8.16634 8.62072 8.16634 8.45964C8.16634 8.29855 8.03576 8.16797 7.87467 8.16797C7.71359 8.16797 7.58301 8.29855 7.58301 8.45964C7.58301 8.62072 7.71359 8.7513 7.87467 8.7513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04167 10.9667C9.13832 10.9667 9.21667 10.8883 9.21667 10.7917C9.21667 10.695 9.13832 10.6167 9.04167 10.6167C8.94502 10.6167 8.86667 10.695 8.86667 10.7917C8.86667 10.8883 8.94502 10.9667 9.04167 10.9667ZM9.04167 11.0833C9.20275 11.0833 9.33333 10.9527 9.33333 10.7917C9.33333 10.6306 9.20275 10.5 9.04167 10.5C8.88058 10.5 8.75 10.6306 8.75 10.7917C8.75 10.9527 8.88058 11.0833 9.04167 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 10.9667C9.72132 10.9667 9.79967 10.8883 9.79967 10.7917C9.79967 10.695 9.72132 10.6167 9.62467 10.6167C9.52802 10.6167 9.44967 10.695 9.44967 10.7917C9.44967 10.8883 9.52802 10.9667 9.62467 10.9667ZM9.62467 11.0833C9.78576 11.0833 9.91634 10.9527 9.91634 10.7917C9.91634 10.6306 9.78576 10.5 9.62467 10.5C9.46359 10.5 9.33301 10.6306 9.33301 10.7917C9.33301 10.9527 9.46359 11.0833 9.62467 11.0833Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62467 10.3846C9.72132 10.3846 9.79967 10.3063 9.79967 10.2096C9.79967 10.113 9.72132 10.0346 9.62467 10.0346C9.52802 10.0346 9.44967 10.113 9.44967 10.2096C9.44967 10.3063 9.52802 10.3846 9.62467 10.3846ZM9.62467 10.5013C9.78576 10.5013 9.91634 10.3707 9.91634 10.2096C9.91634 10.0486 9.78576 9.91797 9.62467 9.91797C9.46359 9.91797 9.33301 10.0486 9.33301 10.2096C9.33301 10.3707 9.46359 10.5013 9.62467 10.5013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04167 10.3846C9.13832 10.3846 9.21667 10.3063 9.21667 10.2096C9.21667 10.113 9.13832 10.0346 9.04167 10.0346C8.94502 10.0346 8.86667 10.113 8.86667 10.2096C8.86667 10.3063 8.94502 10.3846 9.04167 10.3846ZM9.04167 10.5013C9.20275 10.5013 9.33333 10.3707 9.33333 10.2096C9.33333 10.0486 9.20275 9.91797 9.04167 9.91797C8.88058 9.91797 8.75 10.0486 8.75 10.2096C8.75 10.3707 8.88058 10.5013 9.04167 10.5013Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95768 12.1346C5.05433 12.1346 5.13268 12.0563 5.13268 11.9596C5.13268 11.863 5.05433 11.7846 4.95768 11.7846C4.86103 11.7846 4.78268 11.863 4.78268 11.9596C4.78268 12.0563 4.86103 12.1346 4.95768 12.1346ZM4.95768 12.2513C5.11877 12.2513 5.24935 12.1207 5.24935 11.9596C5.24935 11.7986 5.11877 11.668 4.95768 11.668C4.7966 11.668 4.66602 11.7986 4.66602 11.9596C4.66602 12.1207 4.7966 12.2513 4.95768 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.37467 12.1346C4.47132 12.1346 4.54967 12.0563 4.54967 11.9596C4.54967 11.863 4.47132 11.7846 4.37467 11.7846C4.27802 11.7846 4.19967 11.863 4.19967 11.9596C4.19967 12.0563 4.27802 12.1346 4.37467 12.1346ZM4.37467 12.2513C4.53576 12.2513 4.66634 12.1207 4.66634 11.9596C4.66634 11.7986 4.53576 11.668 4.37467 11.668C4.21359 11.668 4.08301 11.7986 4.08301 11.9596C4.08301 12.1207 4.21359 12.2513 4.37467 12.2513Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41634 5.71797C6.67407 5.71797 6.88301 5.50903 6.88301 5.2513C6.88301 4.99357 6.67407 4.78464 6.41634 4.78464C6.15861 4.78464 5.94967 4.99357 5.94967 5.2513C5.94967 5.50903 6.15861 5.71797 6.41634 5.71797ZM6.41634 5.83464C6.73851 5.83464 6.99967 5.57347 6.99967 5.2513C6.99967 4.92914 6.73851 4.66797 6.41634 4.66797C6.09418 4.66797 5.83301 4.92914 5.83301 5.2513C5.83301 5.57347 6.09418 5.83464 6.41634 5.83464Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66634 8.05C4.92407 8.05 5.13301 7.84107 5.13301 7.58333C5.13301 7.3256 4.92407 7.11667 4.66634 7.11667C4.40861 7.11667 4.19967 7.3256 4.19967 7.58333C4.19967 7.84107 4.40861 8.05 4.66634 8.05ZM4.66634 8.16667C4.98851 8.16667 5.24967 7.9055 5.24967 7.58333C5.24967 7.26117 4.98851 7 4.66634 7C4.34418 7 4.08301 7.26117 4.08301 7.58333C4.08301 7.9055 4.34418 8.16667 4.66634 8.16667Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 9.21797C6.09107 9.21797 6.3 9.00904 6.3 8.7513C6.3 8.49357 6.09107 8.28464 5.83333 8.28464C5.5756 8.28464 5.36667 8.49357 5.36667 8.7513C5.36667 9.00904 5.5756 9.21797 5.83333 9.21797ZM5.83333 9.33464C6.1555 9.33464 6.41667 9.07347 6.41667 8.7513C6.41667 8.42914 6.1555 8.16797 5.83333 8.16797C5.51117 8.16797 5.25 8.42914 5.25 8.7513C5.25 9.07347 5.51117 9.33464 5.83333 9.33464Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41634 10.384C6.67407 10.384 6.88301 10.1751 6.88301 9.91732C6.88301 9.65958 6.67407 9.45065 6.41634 9.45065C6.15861 9.45065 5.94967 9.65958 5.94967 9.91732C5.94967 10.1751 6.15861 10.384 6.41634 10.384ZM6.41634 10.5007C6.73851 10.5007 6.99967 10.2395 6.99967 9.91732C6.99967 9.59515 6.73851 9.33398 6.41634 9.33398C6.09418 9.33398 5.83301 9.59515 5.83301 9.91732C5.83301 10.2395 6.09418 10.5007 6.41634 10.5007Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 9.8C9.59107 9.8 9.8 9.59107 9.8 9.33333C9.8 9.0756 9.59107 8.86667 9.33333 8.86667C9.0756 8.86667 8.86667 9.0756 8.86667 9.33333C8.86667 9.59107 9.0756 9.8 9.33333 9.8ZM9.33333 9.91667C9.6555 9.91667 9.91667 9.6555 9.91667 9.33333C9.91667 9.01117 9.6555 8.75 9.33333 8.75C9.01117 8.75 8.75 9.01117 8.75 9.33333C8.75 9.6555 9.01117 9.91667 9.33333 9.91667Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99935 9.21797C7.25708 9.21797 7.46602 9.00904 7.46602 8.7513C7.46602 8.49357 7.25708 8.28464 6.99935 8.28464C6.74162 8.28464 6.53268 8.49357 6.53268 8.7513C6.53268 9.00904 6.74162 9.21797 6.99935 9.21797ZM6.99935 9.33464C7.32151 9.33464 7.58268 9.07347 7.58268 8.7513C7.58268 8.42914 7.32151 8.16797 6.99935 8.16797C6.67718 8.16797 6.41602 8.42914 6.41602 8.7513C6.41602 9.07347 6.67718 9.33464 6.99935 9.33464Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16634 10.384C8.42407 10.384 8.63301 10.1751 8.63301 9.91732C8.63301 9.65958 8.42407 9.45065 8.16634 9.45065C7.90861 9.45065 7.69967 9.65958 7.69967 9.91732C7.69967 10.1751 7.90861 10.384 8.16634 10.384ZM8.16634 10.5007C8.48851 10.5007 8.74967 10.2395 8.74967 9.91732C8.74967 9.59515 8.48851 9.33398 8.16634 9.33398C7.84418 9.33398 7.58301 9.59515 7.58301 9.91732C7.58301 10.2395 7.84418 10.5007 8.16634 10.5007Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41634 11.55C6.67407 11.55 6.88301 11.3411 6.88301 11.0833C6.88301 10.8256 6.67407 10.6167 6.41634 10.6167C6.15861 10.6167 5.94967 10.8256 5.94967 11.0833C5.94967 11.3411 6.15861 11.55 6.41634 11.55ZM6.41634 11.6667C6.73851 11.6667 6.99967 11.4055 6.99967 11.0833C6.99967 10.7612 6.73851 10.5 6.41634 10.5C6.09418 10.5 5.83301 10.7612 5.83301 11.0833C5.83301 11.4055 6.09418 11.6667 6.41634 11.6667Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.66634 10.968C4.92407 10.968 5.13301 10.759 5.13301 10.5013C5.13301 10.2436 4.92407 10.0346 4.66634 10.0346C4.40861 10.0346 4.19967 10.2436 4.19967 10.5013C4.19967 10.759 4.40861 10.968 4.66634 10.968ZM4.66634 11.0846C4.98851 11.0846 5.24967 10.8235 5.24967 10.5013C5.24967 10.1791 4.98851 9.91797 4.66634 9.91797C4.34418 9.91797 4.08301 10.1791 4.08301 10.5013C4.08301 10.8235 4.34418 11.0846 4.66634 11.0846Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 12.134C9.59107 12.134 9.8 11.9251 9.8 11.6673C9.8 11.4096 9.59107 11.2007 9.33333 11.2007C9.0756 11.2007 8.86667 11.4096 8.86667 11.6673C8.86667 11.9251 9.0756 12.134 9.33333 12.134ZM9.33333 12.2507C9.6555 12.2507 9.91667 11.9895 9.91667 11.6673C9.91667 11.3452 9.6555 11.084 9.33333 11.084C9.01117 11.084 8.75 11.3452 8.75 11.6673C8.75 11.9895 9.01117 12.2507 9.33333 12.2507Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.58333 12.134C7.84107 12.134 8.05 11.9251 8.05 11.6673C8.05 11.4096 7.84107 11.2007 7.58333 11.2007C7.3256 11.2007 7.11667 11.4096 7.11667 11.6673C7.11667 11.9251 7.3256 12.134 7.58333 12.134ZM7.58333 12.2507C7.9055 12.2507 8.16667 11.9895 8.16667 11.6673C8.16667 11.3452 7.9055 11.084 7.58333 11.084C7.26117 11.084 7 11.3452 7 11.6673C7 11.9895 7.26117 12.2507 7.58333 12.2507Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 8.63398C9.59107 8.63398 9.8 8.42505 9.8 8.16732C9.8 7.90958 9.59107 7.70065 9.33333 7.70065C9.0756 7.70065 8.86667 7.90958 8.86667 8.16732C8.86667 8.42505 9.0756 8.63398 9.33333 8.63398ZM9.33333 8.75065C9.6555 8.75065 9.91667 8.48948 9.91667 8.16732C9.91667 7.84515 9.6555 7.58398 9.33333 7.58398C9.01117 7.58398 8.75 7.84515 8.75 8.16732C8.75 8.48948 9.01117 8.75065 9.33333 8.75065Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33333 5.71797C9.59107 5.71797 9.8 5.50903 9.8 5.2513C9.8 4.99357 9.59107 4.78464 9.33333 4.78464C9.0756 4.78464 8.86667 4.99357 8.86667 5.2513C8.86667 5.50903 9.0756 5.71797 9.33333 5.71797ZM9.33333 5.83464C9.6555 5.83464 9.91667 5.57347 9.91667 5.2513C9.91667 4.92914 9.6555 4.66797 9.33333 4.66797C9.01117 4.66797 8.75 4.92914 8.75 5.2513C8.75 5.57347 9.01117 5.83464 9.33333 5.83464Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.16634 5.13398C8.42407 5.13398 8.63301 4.92505 8.63301 4.66732C8.63301 4.40958 8.42407 4.20065 8.16634 4.20065C7.90861 4.20065 7.69967 4.40958 7.69967 4.66732C7.69967 4.92505 7.90861 5.13398 8.16634 5.13398ZM8.16634 5.25065C8.48851 5.25065 8.74967 4.98948 8.74967 4.66732C8.74967 4.34515 8.48851 4.08398 8.16634 4.08398C7.84418 4.08398 7.58301 4.34515 7.58301 4.66732C7.58301 4.98948 7.84418 5.25065 8.16634 5.25065Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.74935 6.88398C9.00708 6.88398 9.21602 6.67505 9.21602 6.41732C9.21602 6.15958 9.00708 5.95065 8.74935 5.95065C8.49162 5.95065 8.28268 6.15958 8.28268 6.41732C8.28268 6.67505 8.49162 6.88398 8.74935 6.88398ZM8.74935 7.00065C9.07152 7.00065 9.33268 6.73948 9.33268 6.41732C9.33268 6.09515 9.07152 5.83398 8.74935 5.83398C8.42718 5.83398 8.16602 6.09515 8.16602 6.41732C8.16602 6.73948 8.42718 7.00065 8.74935 7.00065Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24935 6.3C5.50708 6.3 5.71602 6.09107 5.71602 5.83333C5.71602 5.5756 5.50708 5.36667 5.24935 5.36667C4.99162 5.36667 4.78268 5.5756 4.78268 5.83333C4.78268 6.09107 4.99162 6.3 5.24935 6.3ZM5.24935 6.41667C5.57151 6.41667 5.83268 6.1555 5.83268 5.83333C5.83268 5.51117 5.57151 5.25 5.24935 5.25C4.92718 5.25 4.66602 5.51117 4.66602 5.83333C4.66602 6.1555 4.92718 6.41667 5.24935 6.41667Z"
        fill="#333333"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99967 8.05065C7.57957 8.05065 8.04967 7.58055 8.04967 7.00065C8.04967 6.42075 7.57957 5.95065 6.99967 5.95065C6.41978 5.95065 5.94967 6.42075 5.94967 7.00065C5.94967 7.58055 6.41978 8.05065 6.99967 8.05065ZM6.99967 8.16732C7.64401 8.16732 8.16634 7.64498 8.16634 7.00065C8.16634 6.35632 7.64401 5.83398 6.99967 5.83398C6.35534 5.83398 5.83301 6.35632 5.83301 7.00065C5.83301 7.64498 6.35534 8.16732 6.99967 8.16732Z"
        fill="#333333"
      />
    </svg>
  );
};
export default Polly;
